import Layout from '@/components/Layout'
import { LayoutContainer } from '@/components/LayoutContainer'
import { Seo } from '@/components/Seo'

const NotFoundPage = () => (
  <Layout>
    <Seo page="404" />
    <LayoutContainer>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </LayoutContainer>
  </Layout>
)

export default NotFoundPage
